import WorkBench, {Constraints} from "../../components/WorkBench/WorkBench";
import st from "./Editor.module.css";
import {useEffect, useRef, useState} from "react";
import {ReactComponent as Point} from "../../resources/icons/point3.svg";
import TableTopBorder from "../../resources/tableParts/TableTopBorder";
import TableField from "../../resources/tableParts/TableField";
import TableBottomBorder from "../../resources/tableParts/TableBottomBorder";
import TableHeader from "../../resources/tableParts/TableHeader";
import TableTopBorderSelect from "../../resources/tableParts/TableTopBorderSelect";
import TableBorderSelect from "../../resources/tableParts/TableBorderSelect";
import TableBottomBorderSelect from "../../resources/tableParts/TableBottomBorderSelect";
import { v4 as uuidv4 } from 'uuid';
import TableBorderSelectWithPoint from "../../resources/tableParts/TableBorderSelectWithPoint";
import DevelopmentConsole from "../../components/DevelopmentConsole/DevelopmentConsole";
import RelationTypeButton from "../../resources/svgButtons/RelationTypeButton";
import {FieldTypes} from "../../enum/FielsTypes";
import {RelationTypes} from "../../enum/RelationTypes";
import {SaveMode} from "../../enum/SaveMode";
import {createNotification, NotificationStatus} from "../../utils/Notification";
import {Arrow, CreationArrow} from "./editorComponents/Arrow";
import {Positions} from "../../enum/Positions";
import {RelationError} from "../../enum/RelationError";

export const Editor = () => {
    
    const step = 10;
    const MAX_HISTORY = 20;
    const MAX_AMOUNT_OF_TABLES = 20;
    const [isInitialUpdate, setIsInitialUpdate] = useState(true)
    
    let [history, setHistory] = useState([]);
    let [historyIndex, setHistoryIndex] = useState(0);

    const [tables, setTables] = useState(
        {
            // 1: {
            //     name: "table1",
            //     x: 500,
            //     y: 100,
            //     color: "#98c5ff",
            //     fields: {
            //         1: {
            //             name: "id",
            //             type: Types.BIGINT,
            //             relations: [1]
            //         },
            //         2: {
            //             name: "label",
            //             type: Types.VARCHAR,
            //             relations: {}
            //         },
            //         3: {
            //             name: "number",
            //             type: Types.INT,
            //             relations: {}
            //         },
            //     }
            // },
            // 2: {
            //     name: "table2",
            //     x: 800,
            //     y: 100,
            //     color: "#a9ff99",
            //     fields: {
            //         1: {
            //             name: "id",
            //             type: Types.BIGINT,
            //             relations: [1]
            //         },
            //         2: {
            //             name: "name",
            //             type: Types.VARCHAR,
            //             relations: {}
            //         }
            //     }
            // }
        }
    )
    const [relations, setRelations] = useState({})

    const [scale, setScale] = useState(1);
    const [cursorPosition, setCursorPosition] = useState({ x: 0, y: 0 }); // позиция курсора относительно масштаба (чем больше масштаб по модулю, там больше позиция курсора (по сути, смещение по масштабу))

    const [selectedTableId, setSelectedTableId] = useState(null);
    const [selectedField, setSelectedField] = useState(null);
    const [selectedRelationId, setSelectedRelationId] = useState(null);
    const [isDraggingTable, setIsDraggingTable] = useState(false);
    const [tableMoved, setTableMoved] = useState(false);
    const [canvas, setCanvas] = useState({
        isDragging: false,
        initialMouseCoords: { x: 0, y: 0 },
        movingMouseCoords: { x: 0, y: 0 },
        deltaX: 0,
        deltaY: 0
    });
    
    const [isOpenedConsole, setIsOpenedConsole] = useState(false);

    // координаты мыши для вычисления позиции кончика стрелки при создании
    const [mouseCoords, setMouseCoords] = useState();
    const [projectId, setProjectId] = useState(1)

    const canvasRef = useRef(canvas);
    const tablesRef = useRef(tables);
    const relationsRef = useRef(relations);
    const isDraggingTableRef = useRef(isDraggingTable);
    const tableMovedRef = useRef(tableMoved);
    const selectedTableIdRef = useRef(selectedTableId);
    const selectedFieldRef = useRef(selectedField);
    const mouseCoordsRef = useRef(mouseCoords);
    const scaleRef = useRef(scale);
    const cursorPositionRef = useRef(cursorPosition);
    const selectedRelationIdRef = useRef(selectedRelationId);
    const historyIndexRef = useRef(historyIndex);
    useEffect(() => {
        canvasRef.current = canvas;
        tablesRef.current = tables;
        relationsRef.current = relations;
        isDraggingTableRef.current = isDraggingTable;
        tableMovedRef.current = tableMoved;
        selectedTableIdRef.current = selectedTableId;
        selectedFieldRef.current = selectedField;
        mouseCoordsRef.current = mouseCoords;
        scaleRef.current = scale;
        cursorPositionRef.current = cursorPosition;
        selectedRelationIdRef.current = selectedRelationId;
        historyIndexRef.current = historyIndex;
    }, [canvas, tables, relations, isDraggingTable, tableMoved, selectedTableId, selectedField, mouseCoords, scale, cursorPosition, selectedRelationId, historyIndex]);
    
    useEffect(() => {
        let project = localStorage.getItem('project_' + projectId);
        let tables = {};
        let relations = {};
        
        if (project) {
            project = JSON.parse(project);
            if (project.tables) {
                tables = project.tables
                if (project.relations) {
                    relations = project.relations
                    setRelations(relations)
                }
                setTables(tables)
            }
            history.push(JSON.stringify({tables: tables, relations: relations}))
            setHistory([...history])

            // Код сбрасывает связи
        //     Object.entries(tables).forEach(([key, value]) => {
        //         Object.entries(value.fields).forEach(([key2, value2]) => {
        //             tables[key].fields[key2] = {...value2, relations: []]}
        //         });
        //     });
        //    
        }
        setTimeout(() => {
            setIsInitialUpdate(false)
        }, 1000)
        // setRelations({})
        document.body.style.zoom = "100%";
    }, [])

    const createTable = (workBenchX, workBenchY, data = null) => {
        if (Object.keys(tables).length === MAX_AMOUNT_OF_TABLES) {
            createNotification(NotificationStatus.WARNING, "Максимально разрешенное кол-во таблиц уже создано!", "")
            return
        }
        if (data) {
            createTableOnSample(workBenchX, workBenchY, data);
        } else {
            createNewTable(workBenchX, workBenchY);
        }
    }
    
    const createNewTable = (workBenchX, workBenchY) => {
        const tableId = uuidv4();
        tables[`${tableId}`] = {
            x: (workBenchX - canvas.deltaX - cursorPosition.x) / scale,
            y: (workBenchY - canvas.deltaY - cursorPosition.y) / scale,
            name: "table",
            color: getRandomColor(),
        }
        let fields = {}
        fields[uuidv4()] = {
            name: "id",
            type: FieldTypes.BIGINT,
            relations: [],
            constraints: [Constraints.PRIMARY_KEY]
        }
        tables[`${tableId}`].fields = fields
        updateTables(tables, "createNewTable");
        setSelectedTableId(tableId);
    }

    const createTableOnSample = (workBenchX, workBenchY, data) => {
        const copyTableId = uuidv4();
        let newTableData = JSON.parse(JSON.stringify(data));
        Object.entries(newTableData.fields).forEach(([key, value]) => {
            if (newTableData.fields[key].constraints.includes(Constraints.PRIMARY_KEY)) {
                newTableData.fields[uuidv4()] = {...value, relations: [], constraints: [Constraints.PRIMARY_KEY]}
            } else {
                newTableData.fields[uuidv4()] = {...value, relations: [], constraints: []}
            }
            delete newTableData.fields[key];
        });
        tables[`${copyTableId}`] = {
            ...newTableData,
            x: (workBenchX - canvas.deltaX - cursorPosition.x) / scale,
            y: (workBenchY - canvas.deltaY - cursorPosition.y) / scale,
            color: getRandomColor(),
        }
        
        
        updateTables(tables, "createTableOnSample");
        setSelectedTableId(copyTableId);
    }
    
    // selectedNewTableId - id новой таблицы для выделения сразу после создания
    const updateTables = (tables, methodName, needSaveToLocalStorage = true) => {
        setTables({...tables})
        if (needSaveToLocalStorage) {
            saveToLocalStorage(SaveMode.TABLES, methodName)
        }
    }

    const updateRelations = (relations, methodName) => {
        setRelations({...relations})
        saveToLocalStorage(SaveMode.RELATIONS, methodName)
    }

    const updateTablesAndRelations = (tables, relations, methodName) => {
        setTables({...tables})
        setRelations({...relations})
        saveToLocalStorage(SaveMode.ALL, methodName)
    }

    const saveToLocalStorage = (mode, methodName) => {
        let dataToSave;
        
        let historyIndex = historyIndexRef.current;
        
        if (historyIndex < history.length - 1) { // если внесли изменения после отката версии через ctrl+z
            history = history.slice(0, historyIndex + 1)
        }
        history.push(JSON.stringify({tables: tablesRef.current, relations: relationsRef.current}))
        if (history.length > MAX_HISTORY) {
            history = history.reverse().slice(0, MAX_HISTORY).reverse()
        } else {
            historyIndex++
            setHistoryIndex(historyIndex)
        }
        setHistory([...history])
        
        dataToSave = {tables: tablesRef.current, relations: relationsRef.current}
        localStorage.setItem("project_" + projectId, JSON.stringify(dataToSave));
        console.log(`Данные ${mode} сохранены в localStorage из ${methodName}`);
    };

    const updateFieldType = (tableId, fieldId, newType) => {
        tables[tableId].fields[fieldId].type = newType;
        // проверка, что типы данных у связанных полей такие же
        const fieldRelations = tables[tableId].fields[fieldId].relations;
        for (let i = 0; i < fieldRelations.length; i++) {
            const relation = relations[fieldRelations[i]]
            const otherTableIdAndFieldId = getOtherTableAndFieldFromRelation(relation, tableId, fieldId);
            const hasRelationTypeError = newType !== tables[otherTableIdAndFieldId.tableId].fields[otherTableIdAndFieldId.fieldId].type
            manageRelationError(hasRelationTypeError, RelationError.DIFFERENT_TYPES_OF_RELATED_FIELDS, fieldRelations[i])
            if (hasRelationTypeError) {
                createNotification(NotificationStatus.WARNING, `Связываемые поля имеют несовместимые типы данных (${newType}, ${tables[otherTableIdAndFieldId.tableId].fields[otherTableIdAndFieldId.fieldId].type})`, "Ошибка смены типа данных")
            }
        }
        
        updateTablesAndRelations(tables, relations, "changeFieldType")
    }

    const getRandomColor = () => {
        const contrast = Object.keys(tables).length ? Object.keys(tables).length % 3 : 0;
        let colorsCoif = [];
        for (let i = 0; i < 3; i++) {
            colorsCoif[i] = Math.floor(Math.random() * 255) ;
            if (contrast === i) colorsCoif[i] = 255;
        }
        return `#${colorsCoif[0].toString(16).padStart(2, '0')}${colorsCoif[1].toString(16).padStart(2, '0')}${colorsCoif[2].toString(16).padStart(2, '0')}`;
    }

    const selectTable = (e, id) => {
        if (e) { // было нажатие по таблице на канвасе
            e.stopPropagation();
            setIsDraggingTable(true);
        } 
        unSelectLastSelectedTable();
        setSelectedTableId(id);
    }

    const unSelectLastSelectedTable = () => {
        setSelectedTableId(null);
    }

    const selectCanvas = (e) => {
        e.stopPropagation();
        setSelectedField(null)
        unSelectLastSelectedTable();
        // Начальная и смещенная позиции находятся в одной точке в начале смещения канваса
        setCanvas({...canvas, isDragging: true, initialMouseCoords: {x: e.clientX, y: e.clientY}, movingMouseCoords: {x: e.clientX, y: e.clientY}})
    }

    const stopDragging = () => {
        if (isDraggingTableRef.current) {
            setIsDraggingTable(false)
            if (tableMovedRef.current) {
                saveToLocalStorage(SaveMode.TABLES, "stopDragging")
            }
            setTableMoved(false)
            setSelectedRelationId(null)
        }
        if (canvasRef.current.isDragging) {
            const canvas = canvasRef.current;
            setCanvas({...canvas, isDragging: false,
                deltaX: canvas.deltaX + canvas.movingMouseCoords.x - canvas.initialMouseCoords.x, deltaY: canvas.deltaY + canvas.movingMouseCoords.y - canvas.initialMouseCoords.y, // прибавляем к делтам смещение
                initialMouseCoords: {x: 0, y: 0}, movingMouseCoords: {x: 0, y: 0} // обнуляем начальную и смещенную позиции
            })
            setSelectedRelationId(null)
        }
        if (selectedFieldRef.current) {
            setSelectedField(null)
            setSelectedRelationId(null)
        }
    }
    
    const [lastMouseMoveTime, setLastMouseMoveTime] = useState(0);
    const handleWindowMouseMove = (e) => {
        const currentTime = Date.now();
        const isAvailableByTime = currentTime - lastMouseMoveTime > 200;
        if (selectedFieldRef.current && isAvailableByTime) {
            setMouseCoords(    {x: (e.clientX - getCurrentRefOffset(0)) / scaleRef.current, y: (e.clientY - getCurrentRefOffset(1)) / scaleRef.current})
            setLastMouseMoveTime(currentTime);
        } else if (isDraggingTableRef.current && isAvailableByTime){
            setLastMouseMoveTime(currentTime);
            if (!tableMovedRef.current)
            setTableMoved(true)
            moveTable(e);
        }
        else if (canvasRef.current.isDragging && isAvailableByTime) {
            setLastMouseMoveTime(currentTime);
            moveCanvas(e);
        }
    }

    const moveTable = (e) => {
        const selectedTableId = selectedTableIdRef.current;
        const tables = tablesRef.current;
        const scale = scaleRef.current;
        if (selectedTableId === null || isDraggingTableRef.current === false) return;
        // Определяем новую позицию, учитывая масштаб
        tables[selectedTableId] = { ...tables[selectedTableId], x: (tables[selectedTableId].x + e.movementX / scale), y: (tables[selectedTableId].y + e.movementY / scale) } // прибавляем смещение относительно scale
        setTables({...tables})
    }

    const moveCanvas = (e) => {
        if (canvasRef.current.isDragging) {
            // изменяем координаты смещенной позиции канваса
            setCanvas({
                ...canvasRef.current,
                movingMouseCoords: { x: e.clientX, y: e.clientY },
            });
        }
    };
    
    const removeCTLWheel = (e) => {
        if (e.ctrlKey) {
            // Предотвращаем стандартное действие (зум)
            e.preventDefault();
        }
    }
    
    const clickFieldRelationPoint = (e, fieldId, position) => {
        e.stopPropagation()
        setSelectedField({
            fieldId: fieldId,
            position: position
        })

        const startDeltaX = position === Positions.RIGHT ? 200 : 0;

        const fieldStep = 20;
        let fieldIndex = Object.keys(tables[selectedTableId].fields).findIndex((findFieldId) => findFieldId === fieldId);
        const startDeltaY = 54 + fieldStep * fieldIndex;
        
        setMouseCoords({ x: tables[selectedTableId].x + startDeltaX, y: tables[selectedTableId].y + startDeltaY})
    }
    
    const selectTargetRelationField = (e, targetTableId, targetFieldId) => {
        if (targetTableId && selectedField && targetTableId !== selectedTableId && selectedField.fieldId !== targetFieldId) {
            const relationId = uuidv4()
            let relationErrors = []
            if (!(tables[selectedTableId].fields[selectedField.fieldId].type === tables[targetTableId].fields[targetFieldId].type)) {
                relationErrors.push(RelationError.DIFFERENT_TYPES_OF_RELATED_FIELDS)
                createNotification(NotificationStatus.WARNING, `У связываемых полей несовместимые типы данных [${tables[selectedTableId].fields[selectedField.fieldId].type}, ${tables[targetTableId].fields[targetFieldId].type}]`, "Невозможная связь")
            }
            relations[relationId] = {
                table1: selectedTableId,
                table1Field: selectedField.fieldId,
                table2: targetTableId,
                table2Field: targetFieldId,
                relationType: RelationTypes.ONE_TO_ONE,
                relationErrors: relationErrors,
            }
            
            tables[selectedTableId].fields[selectedField.fieldId].relations.push(relationId)
            tables[targetTableId].fields[targetFieldId].relations.push(relationId)
            
            // добавляем constraint ForeignKey полю, с которым связали PK
            checkFKConstraints(selectedTableId, selectedField.fieldId)
            updateTablesAndRelations(tables, relations, "selectTargetRelationField")
        }
    }
    
    // проверяет связи поля с другими полями и расставляет PK, если надо
    const checkFKConstraints = (selectedTableId, selectedFieldId) => {
        for (const relation of tables[selectedTableId].fields[selectedFieldId].relations) {
            const otherTableIdAndFieldId = getOtherTableAndFieldFromRelation(relations[relation], selectedTableId, selectedFieldId);
            const targetTableId = otherTableIdAndFieldId.tableId;
            const targetFieldId = otherTableIdAndFieldId.fieldId;
            // Если не оба поля вместе имеют PrimaryKey
            if (!(hasConstraint(selectedTableId, selectedFieldId, Constraints.PRIMARY_KEY) && hasConstraint(targetTableId, targetFieldId, Constraints.PRIMARY_KEY))) {
                if (hasConstraint(selectedTableId, selectedFieldId, Constraints.PRIMARY_KEY) && !hasConstraint(targetTableId, targetFieldId, Constraints.FOREIGN_KEY)) { // если у первого полю есть PK, а у второго нет FK
                    setConstraint(targetTableId, targetFieldId, Constraints.FOREIGN_KEY)
                } else if (hasConstraint(targetTableId, targetFieldId, Constraints.PRIMARY_KEY) && !hasConstraint(selectedTableId, selectedFieldId, Constraints.FOREIGN_KEY)) { // наоборот
                    setConstraint(selectedTableId, selectedFieldId, Constraints.FOREIGN_KEY)
                } else { // ни у кого нет PK
                    // проверяем, что у полей нет FK ограничений от других связей
                    // если нет, то ограничения удаляются
                    if (!hasOtherFKRelations(selectedTableId, selectedFieldId)) {
                        deleteConstraint(selectedTableId, selectedFieldId, Constraints.FOREIGN_KEY);
                    }
                    if (!hasOtherFKRelations(targetTableId, targetFieldId)) {
                        deleteConstraint(targetTableId, targetFieldId, Constraints.FOREIGN_KEY);
                    }
                }
            } else { // если оба поля имеют PK
                setConstraint(selectedTableId, selectedFieldId, Constraints.FOREIGN_KEY)
            }
        }
    }
    const setConstraint = (tableId, fieldId, constraint) => {
        if (!hasConstraint(tableId, fieldId, constraint)) {
            tables[tableId].fields[fieldId]?.constraints?.push(constraint);
        }
    }

    const deleteConstraint = (tableId, fieldId, constraint) => {
        tables[tableId].fields[fieldId].constraints = tables[tableId].fields[fieldId]?.constraints?.filter(c => c !== constraint);
    }
    
    const switchConstraint = (tableId, fieldId, constraint) => {
        if (hasConstraint(tableId, fieldId, constraint)) {
            deleteConstraint(tableId, fieldId, constraint)
        } else {
            setConstraint(tableId, fieldId, constraint)
        }
    }
    
    
    const hasConstraint = (tableId, fieldId, constraint) => {
        return tables[tableId].fields[fieldId]?.constraints?.includes(constraint);
    }

    // Решение выбрано для возможности перетаскивания объектов, даже если курсор на какой-то панели
    useEffect(() => {

        window.addEventListener('mousemove', handleWindowMouseMove);
        window.addEventListener('mouseup', stopDragging);
        window.addEventListener('wheel', removeCTLWheel, { passive: false });

        return () => {
            window.removeEventListener(
                'mousemove',
                handleWindowMouseMove,
            );
            window.removeEventListener(
                'mouseup',
                stopDragging,
            );
            window.removeEventListener(
                'wheel',
                removeCTLWheel,
            );
        };
    }, []);

    const handleWheel = (e) => {
        if (scale <= 0.3 && e.deltaY > 0) return // e.deltaY > 0 значит колесико вниз
        else if (scale >= 10 && e.deltaY < 0) return;
        const scaleAdjust = e.deltaY > 0 ? 0.9 : 1.1; // Значение изменения масштаба
        const newScale = scale * scaleAdjust;

        // Позиция канваса должна корректироваться, чтобы курсор оставался над той же точкой контента
        const newPos = {
            x: cursorPosition.x - (e.clientX - cursorPosition.x - canvas.deltaX) * (scaleAdjust - 1),
            y: cursorPosition.y - (e.clientY - cursorPosition.y - canvas.deltaY) * (scaleAdjust - 1)
        };

        // Обновление состояния
        setScale(newScale);
        setCursorPosition(newPos);
    };

    // текущее смещение относительно курсора (используется, при изменении масштаба + смещение канваса при перемещении - изначальные координаты канваса + имеющееся смещение канваса
    const getCurrentOffset = (coordinate) => {
        if (coordinate === 0)
            return cursorPosition.x + canvas.movingMouseCoords.x - canvas.initialMouseCoords.x + canvas.deltaX;
        else if (coordinate === 1)
            return cursorPosition.y + canvas.movingMouseCoords.y - canvas.initialMouseCoords.y + canvas.deltaY;
    }

    // вычисление смещения координат стрелки под мышкой относительно смещения канваса и смещения содержимого после масштабирования
    const getCurrentRefOffset = (coordinate) => {
        const cursorPosition = cursorPositionRef.current;
        const canvas = canvasRef.current;
        if (coordinate === 0)
            return cursorPosition.x + canvas.deltaX;
        else if (coordinate === 1)
            return cursorPosition.y + canvas.deltaY;
    }

    const deleteTable = (e, tableId) => {
        if (selectedTableId === tableId) {
            setSelectedTableId(null)
        }
        e.stopPropagation()
        deleteRelations(tableId)
        delete tables[tableId];
        updateTablesAndRelations(tables, relations, "deleteTable")
    }
    
    const deleteField = (e, tableId, fieldId) => {
        tables[tableId].fields[fieldId].relations.map(relationId => {
            deleteRelation(relationId)
        })
        delete tables[tableId].fields[fieldId]
        updateTablesAndRelations(tables, relations, "deleteField")
    }

    const deleteRelations = (tableId) => {
        // получение всех связей таблички 
        const relationsToRemoveIds = [];
        for (let i = 0; i < Object.keys(tables[tableId].fields).length; i++) {
            const fieldId = Object.keys(tables[tableId].fields)[i];
            if (tables[tableId].fields[fieldId].relations.length) {
                relationsToRemoveIds.push(...tables[tableId].fields[fieldId].relations)
            }
        }

        // удаление всех связей
        for (let i = 0; i < relationsToRemoveIds.length; i++) {
            deleteRelation(relationsToRemoveIds[i])
        }
    }
    
    const selectRelationType = (relationType) => {
        relations[selectedRelationId].relationType = relationType;
        updateRelations(relations, "selectRelationType");
        setSelectedRelationId(null)
    }
    
    const deleteRelation = (relationId, needRelationUpdate = false, needTableUpdate = false) => {
        const relationTableId1 = relations[relationId].table1;
        const relationTableFieldId1 = relations[relationId].table1Field;
        const relationTableId2 = relations[relationId].table2;
        const relationTableFieldId2 = relations[relationId].table2Field;
        // чистим связь в связанных таблицах
        tables[relationTableId1].fields[relationTableFieldId1].relations = tables[relationTableId1].fields[relationTableFieldId1].relations.filter(( relation ) => relation !== relationId);
        tables[relationTableId2].fields[relationTableFieldId2].relations = tables[relationTableId2].fields[relationTableFieldId2].relations.filter(( relation ) => relation !== relationId);

        // чистим FK
        if (hasConstraint(relationTableId1, relationTableFieldId1, Constraints.FOREIGN_KEY)) {
            if (!hasOtherFKRelations(relationTableId1, relationTableFieldId1)) {
                deleteConstraint(relationTableId1, relationTableFieldId1, Constraints.FOREIGN_KEY);
            }
        }
        else if (hasConstraint(relationTableId2, relationTableFieldId2, Constraints.FOREIGN_KEY)) {
            if (!hasOtherFKRelations(relationTableId2, relationTableFieldId2)) {
                deleteConstraint(relationTableId2, relationTableFieldId2, Constraints.FOREIGN_KEY);
            }
        }
        
        delete relations[relationId];
        
        if (needRelationUpdate && needTableUpdate) {
            updateTablesAndRelations(tables, relations, "deleteRelation")
        }
        else if (needRelationUpdate) {
            updateRelations(relations, "deleteRelation")
        } 
        else if (needTableUpdate) {
            updateTables(tables, "deleteRelation")
        }
        
        if (selectedRelationId === relationId) {
            setSelectedRelationId(null)
        }
    }
    
    const hasOtherFKRelations = (tableId, fieldId) => {
        const relationsIds = tables[tableId].fields[fieldId].relations
        if (!relationsIds.length) {
            return false;
        }
        for (let i = 0; i < relationsIds.length; i++) {
            const relation = relations[relationsIds[i]];
            const otherTableIdAndFieldId = getOtherTableAndFieldFromRelation(relation, tableId, fieldId);
            if (tables[otherTableIdAndFieldId.tableId].fields[otherTableIdAndFieldId.fieldId]?.constraints?.includes(Constraints.PRIMARY_KEY)) {
                return true;
            }
        }
        return false;
    }
    
    // Возвращает из связей другую таблицу и поле
    const getOtherTableAndFieldFromRelation = (relation, tableId, fieldId) => {
        if (relation.table1 === tableId && relation.table1Field === fieldId) {
            return {tableId: relation.table2, fieldId: relation.table2Field}
        } else if (relation.table2 === tableId && relation.table2Field === fieldId) {
            return {tableId: relation.table1, fieldId: relation.table1Field}
        }
    }

    const handleKeyDown = (event) => {
        if (document.activeElement.tagName.toLowerCase() === 'input') {
            return;
        }
        if (event.keyCode === 46) { // проверяем код клавиши delete
            if (selectedTableId && !selectedRelationId) {
                deleteTable(event, selectedTableId);
            } else if (selectedRelationId) {
                deleteRelation(selectedRelationId, true, true)
                setSelectedRelationId(null);
            }
        }
        if (event.ctrlKey && (event.key.toLowerCase() === 'z' || event.key.toLowerCase() === 'я')) {
            if (event.shiftKey && historyIndex < MAX_HISTORY - 1 && historyIndex < history.length - 1) {
                historyIndex++;
                
            } else if (!event.shiftKey && historyIndex > 0) {
                historyIndex--;
            }
            setTables(JSON.parse(history[historyIndex]).tables);
            setRelations(JSON.parse(history[historyIndex]).relations);
            setHistoryIndex(historyIndex);
            const dataToSave = {tables: JSON.parse(history[historyIndex]).tables, relations: JSON.parse(history[historyIndex]).relations}
            localStorage.setItem("project_" + projectId, JSON.stringify(dataToSave));
            console.log(`Данные проекта обновлены из истории`);
        }
    }

    const selectRelation = (e, relationId) => {
        setMouseCoords(    {x: (e.clientX - getCurrentRefOffset(0)) / scaleRef.current, y: (e.clientY - getCurrentRefOffset(1)) / scaleRef.current})
        setSelectedRelationId(relationId)
    }
    
    const manageRelationError = (hasRelationError, relationError, relationId = null) => {
        relationId = relationId ? relationId : selectedRelationId;
        
        if (!relations[relationId]?.relationErrors) {
            relations[relationId].relationErrors = [];
        } 
        if (hasRelationError && !relations[relationId].relationErrors?.includes(relationError)) {
            relations[relationId].relationErrors?.push(relationError)
        } else if (!hasRelationError && relations[relationId].relationErrors?.includes(relationError)) {
            relations[relationId].relationErrors = relations[relationId].relationErrors?.filter(relationErr => relationErr !== relationError)
        }
    }
    
    // метод сортировки словаря связей относительно кол-ва ошибок (нужен для отобажения ошибочных связей выше остальных)
    const getSortedRelationsByErrors = (relations) => {
        let array = Object.keys(relations).map(key => ({
            id: key,
            ...relations[key]
        }));

        array.sort((a, b) => {
            let lenErrA = a?.relationErrors?.length;
            let lenErrB = b?.relationErrors?.length;
            if (lenErrA === null || lenErrA === undefined) { // нужно для обратной совместимости
                lenErrA = 0;
            }
            if (lenErrB === null || lenErrB === undefined) {
                lenErrB = 0;
            }
            return (lenErrB === 0 ? -1 : lenErrB) - (lenErrA === 0 ? -1 : lenErrA);
        }).reverse();
        
        let sortedObj = {};
        array.forEach(item => {
            sortedObj[item.id] = item;
            delete sortedObj[item.id].id;
        });
        return sortedObj
    }

    return (
        <div style={{width: "100%", height: "100vh", overflow: "hidden"}} tabIndex="0" onKeyDown={handleKeyDown} >

            <WorkBench tables={tables} createTable={createTable} selectTable={selectTable} selectedTableId={selectedTableId} updateTables={updateTables} deleteTable={deleteTable} deleteField={deleteField} updateFieldType={updateFieldType} checkFKConstraints={checkFKConstraints}/>
            <DevelopmentConsole tables={tables} setTables={setTables} relations={relations} setRelations={setRelations} isOpenedConsole={isOpenedConsole} setIsOpenedConsole={setIsOpenedConsole} saveToLocalStorage={saveToLocalStorage} projectId={projectId}/>
            <div className={st.canvasBlock}>
                <svg
                    width={window.innerWidth}
                    height={window.innerHeight}
                    onWheel={handleWheel}
                >
                    <defs>
                        <pattern id="backgroundPattern" patternUnits="userSpaceOnUse" width={step * scale} height={step * scale} x={getCurrentOffset(0)} y={getCurrentOffset(1)}>
                            <Point width={step * scale} height={step * scale} />
                        </pattern>
                    </defs>
                    <rect onMouseDown={selectCanvas} x={0} y={0} width={window.innerWidth} height={window.innerHeight} fill="url(#backgroundPattern)"/>
                    <g
                        transform={`translate(${getCurrentOffset(0)}, ${getCurrentOffset(1)}) scale(${scale})`}>
                        {Object.keys(getSortedRelationsByErrors(relations)).map((relationId) => (
                            <Arrow key={relationId} relationId={relationId} relation={relations[relationId]} tables={tables} selectedRelationId={selectedRelationId} setSelectedRelationId={setSelectedRelationId} selectRelation={selectRelation} isInitialUpdate={isInitialUpdate}/>
                        ))}
                        {selectedField &&
                            <CreationArrow selectedTableId={selectedTableId} tables={tables} mouseCoords={mouseCoords} selectedField={selectedField}/>
                        }
                        {Object.keys(tables).map((tableId) => (
                            <svg className={st.table} key={tableId} x={step * Math.round(tables[tableId].x / step)} y={step * Math.round(tables[tableId].y / step) + step / 2} fill="none" xmlns="http://www.w3.org/2000/svg" onMouseDown={(e) => selectTable(e, tableId)}>
                                {selectedTableId === tableId && (
                                    <>
                                        <TableTopBorderSelect/>
                                        <TableBorderSelect x={4} y={6} height={38}/>
                                        <TableBorderSelect x={205} y={6} height={38}/>
                                    </>
                                )}
                                <TableTopBorder color={tables[tableId].color}/>
                                <TableHeader text={tables[tableId].name}/>
                                <g>
                                    {Object.keys(tables[tableId]?.fields).map((fieldId, fieldIndex) => (
                                        <svg key={fieldId}>
                                            <TableField deltaY={fieldIndex} key={fieldId} field={tables[tableId].fields[fieldId]} tableId={tableId} fieldId={fieldId} selectRelationField={selectTargetRelationField} isPrimaryKey={tables[tableId].fields[fieldId]?.constraints?.includes(Constraints.PRIMARY_KEY)} isForeignKey={tables[tableId].fields[fieldId]?.constraints?.includes(Constraints.FOREIGN_KEY)}/>
                                            {selectedTableId === tableId && (
                                                <>
                                                    <TableBorderSelectWithPoint x={4} y={44 + 20 * fieldIndex} height={fieldIndex === Object.keys(tables[tableId].fields).length - 1 ? 19 : 20} selectFieldPoint={clickFieldRelationPoint} fieldId={fieldId} position={Positions.LEFT} tables={tables} tableId={tableId}/>
                                                    <TableBorderSelectWithPoint x={205} y={44 + 20 * fieldIndex} height={20} selectFieldPoint={clickFieldRelationPoint} fieldId={fieldId} position={Positions.RIGHT} tables={tables} tableId={tableId}/>
                                                </>
                                            )}
                                        </svg>
                                    ))}
                                </g>
                                <TableBottomBorder deltaY={Object.keys(tables[tableId].fields).length}/>
                                {selectedTableId === tableId && (
                                    <TableBottomBorderSelect x={3.5} y={42 + 20 * Object.keys(tables[tableId].fields).length}/>
                                )}
                            </svg>
                        ))}
                        {selectedRelationId &&
                            <RelationTypeButton mouseCoords={mouseCoords} selectedRelationId={selectedRelationId} deleteRelation={deleteRelation} selectRelationType={selectRelationType} relation={relations[selectedRelationId]} tables={tables} manageRelationError={manageRelationError}/>
                        }
                    </g>
                </svg>
            </div>
        </div>
    )
}

